// Todo: Investigate/Optimize this implementation with @cross-platform-lib\providers\ModalProvider\Modals\ShareEventModal\useEventSharing.tsx
// We appear to have two implementations with the same functionality
// https://huddleinc.atlassian.net/browse/FAN-8040

import { Linking, Share } from 'react-native';

export interface ShareParams {
  eventUrl: string;
  eventName: string;
  schoolName: string;
  isFundraiser: boolean;
}

export const facebookShareEvent = ({ eventUrl }: ShareParams) => {
  let facebookParameters = [];
  const facebookShareURL = eventUrl;
  if (facebookShareURL) facebookParameters.push('u=' + encodeURI(facebookShareURL));
  const url = 'https://www.facebook.com/sharer/sharer.php?' + facebookParameters.join('&');

  Linking.openURL(url);
};

export const twitterShareEvent = ({ eventUrl, schoolName, eventName, isFundraiser }: ShareParams) => {
  let twitterParameters = [];
  const twitterShareURL = eventUrl;
  const tweetContent = isFundraiser
    ? `Support ${schoolName}'s fundraiser - ${eventName}`
    : `Check out ${schoolName}'s event - ${eventName}`;
  if (twitterShareURL) twitterParameters.push('url=' + encodeURI(twitterShareURL));
  if (tweetContent) twitterParameters.push('text=' + encodeURI(tweetContent));
  const url = 'https://twitter.com/intent/tweet?' + twitterParameters.join('&');

  Linking.openURL(url);
};

export const nativeShareEvent = async ({ eventUrl, schoolName, eventName, isFundraiser }: ShareParams) => {
  await Share.share({
    message: isFundraiser
      ? `Support ${schoolName}'s fundraiser - ${eventName} ${eventUrl}`
      : `Check out ${schoolName}'s event - ${eventName}`
  });
};

export const emailShareEvent = ({ schoolName, eventName, eventUrl, isFundraiser }: ShareParams) => {
  const emailSubject = encodeURI(
    isFundraiser
      ? `Support ${schoolName}'s fundraiser - ${eventName}`
      : `Check out ${schoolName}'s event - ${eventName}`
  );
  const emailBody = encodeURI(
    isFundraiser
      ? ` 
Hello,

I hope this email finds you well. ${schoolName} is organizing a GoFan fundraiser: ${eventName} and I am reaching out to you today to ask for your support.

Here's how you can help:

  1. Make a donation: Your financial support, no matter the amount, will directly benefit ${schoolName} and its community. You can donate securely through this link: ${eventUrl}

  2. Spread the word: Share this fundraiser with your friends, family, and colleagues who may be interested in supporting our ${schoolName}. Your outreach can make a difference in reaching our fundraising goals. Here's the link to share: ${eventUrl}

Thank you in advance for considering my request. Your support will directly benefit ${schoolName}!
    `
      : `${eventName} is happening at ${schoolName}. 
      Who's with me? Get your tickets at ${eventUrl}`
  );

  Linking.openURL(`mailto:?subject=${emailSubject}&body=${emailBody}`);
};
