import React, { useState, useMemo } from 'react';
import { View } from 'react-native';
import { Clipboard } from 'react-native';

import { styles } from './EventShareWidget.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { Carbonicons } from '@gf/cross-platform-lib/components';
import { getEventUrl } from '@gf/cross-platform-lib/providers/ModalProvider/Modals/ShareEventModal/utils';
import { EventCombined } from '@gf/cross-platform-lib/interfaces';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import {
  ShareParams,
  facebookShareEvent,
  twitterShareEvent,
  nativeShareEvent,
  emailShareEvent,
  testProperties
} from '@gf/cross-platform-lib/utils';

const ShareWidgetContainer = styled(View)`
  ${styles.shareWidgetContainer}
`;
const TextContainer = styled(View)`
  ${styles.textContainer}
`;
const IconGroupContainer = styled(View)`
  ${styles.iconGroupContainer}
`;
const IconButton = styled.TouchableOpacity`
  ${styles.iconContainer}
`;
export type EventShareWidgetProps = {
  event: EventCombined;
  schoolName: string;
  isFundraiser?: boolean;
};

export const EventShareWidget = ({ event, schoolName, isFundraiser = true }: EventShareWidgetProps) => {
  const [copied, setCopied] = useState(false);
  const { isNativeApp, isMobile } = useMediaQuery();
  const eventUrl = useMemo(() => getEventUrl(event.id, event.isSeason ?? false), [event]);

  const shareParams: ShareParams = {
    eventUrl: eventUrl,
    eventName: event.title ?? 'Untitled',
    schoolName: schoolName,
    isFundraiser
  };

  const copyLink = (url: string) => {
    // TODO: replace deprecated Clipboard from 'react-native' with https://github.com/react-native-clipboard/clipboard
    Clipboard.setString(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  return (
    <ShareWidgetContainer isMobile={isMobile} isFundraiser={isFundraiser} {...testProperties('share-components')}>
      <TextContainer
        style={{
          marginRight: isMobile ? 0 : 11,
          marginBottom: isMobile ? 8 : 0,
          alignSelf: isFundraiser ? 'center' : 'flex-start'
        }}
      >
        <StyledText color='#323232' typeToken={'body01'} textAlign={'center'}>
          Share
        </StyledText>
      </TextContainer>
      <IconGroupContainer>
        <IconButton style={{ marginLeft: isMobile ? 0 : 11 }} onPress={() => facebookShareEvent(shareParams)}>
          <Carbonicons name='facebook' size={18} color='black' />
        </IconButton>
        <IconButton onPress={() => twitterShareEvent(shareParams)}>
          <Carbonicons name='logoTwitter' size={18} color='black' />
        </IconButton>
        {!isNativeApp && (
          <IconButton onPress={() => emailShareEvent(shareParams)}>
            <Carbonicons name='email' size={18} color='black' />
          </IconButton>
        )}
        <IconButton onPress={() => copyLink(eventUrl)}>
          {copied ? (
            <StyledText color='#323232' typeToken={'label01'} textAlign={'center'}>
              Copied
            </StyledText>
          ) : (
            <Carbonicons name='copyLink' size={18} color='black' />
          )}
        </IconButton>
        {isNativeApp && (
          <IconButton onPress={() => nativeShareEvent(shareParams)}>
            <Carbonicons name='export' size={18} color='black' />
          </IconButton>
        )}
      </IconGroupContainer>
    </ShareWidgetContainer>
  );
};

export default EventShareWidget;
